var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect, useMemo, useState } from "react";
import { useIsDiff } from "../../../../common/hooks/useIsDiff";
import { BUSINESS_TAG } from "../business/types";
import { DictionaryContext } from "../constants";
import { DICTIONARY_KEY } from "../types";
import { compileBusinessBranchDataFromLocations, fillInBusinessInfo, fillInNameInfo, filterBusinessBranch, } from "./helpers";
import { BusinessBranchObjectKeys } from "./types";
import { useMergeBusinessBranchInfo } from "./useMergeBusinessBranchInfo";
var useMergeData = function (locations, brands, businesses, companies) {
    var businessBranches = useMemo(function () {
        return fillInNameInfo(BusinessBranchObjectKeys.company, fillInNameInfo(BusinessBranchObjectKeys.brand, fillInBusinessInfo(compileBusinessBranchDataFromLocations(locations), businesses), brands), companies).sort(function (a, b) { return a.business_branch_id - b.business_branch_id; });
    }, [locations, businesses, brands, companies]);
    return useMergeBusinessBranchInfo(businessBranches);
};
export var useConstructBusinessBranchData = function () {
    var _a = useContext(DictionaryContext), _b = _a[0], locations = _b.locations, businesses = _b.businesses, brands = _b.brands, companies = _b.companies, setContext = _a[1];
    var mergedData = useMergeData(locations, brands, businesses, companies);
    useEffect(function () {
        setContext(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[DICTIONARY_KEY.BUSINESS_BRANCHES] = mergedData, _a)));
        });
    }, [mergedData]);
};
export var useConstructRawBusinessBranchData = function () {
    var _a = useContext(DictionaryContext), _b = _a[0].raw, locations = _b.locations, businesses = _b.businesses, brands = _b.brands, companies = _b.companies, setContext = _a[1];
    var mergedData = useMergeData(locations, brands, businesses, companies);
    useEffect(function () {
        setContext(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), { raw: __assign(__assign({}, prev.raw), (_a = {}, _a[DICTIONARY_KEY.BUSINESS_BRANCHES] = mergedData, _a)) }));
        });
    }, [mergedData]);
};
var idsValue = function (ids) { return ids.join(","); };
var bbsValue = function (bbs) {
    return bbs.map(function (_a) {
        var business_branch_id = _a.business_branch_id;
        return business_branch_id;
    }).join(",");
};
export var useBusinessBranchFilter = function (bb, ids) {
    var isIdsDiff = useIsDiff(idsValue);
    var isBBDiff = useIsDiff(bbsValue);
    var _a = useState(bb), bbs = _a[0], setBbs = _a[1];
    useEffect(function () {
        if (!ids.length)
            return setBbs(bb);
        if (isIdsDiff(ids) || isBBDiff(bb)) {
            setBbs(filterBusinessBranch(bb, [], [], [], [], ids));
        }
    }, [ids, bb]);
    return bbs;
};
export var useBusinessBranchContext = function () {
    var ids = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        ids[_i] = arguments[_i];
    }
    var businessBranches = useContext(DictionaryContext)[0].businessBranches;
    return useBusinessBranchFilter(businessBranches, ids);
};
export var useRawBusinessBranchContext = function () {
    var ids = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        ids[_i] = arguments[_i];
    }
    var businessBranches = useContext(DictionaryContext)[0].raw.businessBranches;
    return useBusinessBranchFilter(businessBranches, ids);
};
export var useAllBusinessBranchIds = function () {
    var businessBranches = useContext(DictionaryContext)[0].businessBranches;
    return useMemo(function () {
        return businessBranches.map(function (_a) {
            var business_branch_id = _a.business_branch_id;
            return business_branch_id;
        });
    }, [businessBranches]);
};
export var useIs24hOutlet = function (bbid) {
    var businessBranches = useContext(DictionaryContext)[0].businessBranches;
    return useMemo(function () {
        var _a;
        return (((_a = businessBranches.filter(function (businessBranch) {
            return businessBranch.business_branch_id === bbid;
        })[0]) === null || _a === void 0 ? void 0 : _a.location.h24) || false); // a business branch is 24h if the location/outlet is marked as 24h
    }, [businessBranches, bbid]);
};
export var useIsDDDFBusiness = function (business_id) {
    var bblist = useBusinessBranchContext();
    return useMemo(function () {
        var _a, _b, _c, _d, _e;
        return ((_e = (_d = (_c = (_b = (_a = bblist
            .filter(function (_a) {
            var id = _a.business.id;
            return Number(business_id) === id;
        })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.business) === null || _c === void 0 ? void 0 : _c.tags) === null || _d === void 0 ? void 0 : _d.includes(BUSINESS_TAG.DDDF)) !== null && _e !== void 0 ? _e : false);
    }, [business_id, bblist]);
};
