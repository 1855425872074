import { useCallback, useRef } from "react";
export var useIsDiff = function (valuator) {
    var snapshot = useRef("");
    return useCallback(function (value) {
        var newsnapshot = valuator(value);
        if (newsnapshot !== snapshot.current) {
            snapshot.current = newsnapshot;
            return true;
        }
        return false;
    }, []);
};
